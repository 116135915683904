interface PromotionResultProps {
    Product: string,
    Description: string,
    Image: string,
    Logo: string|null
}

const PromotionResult = ({ Product, Description, Image, Logo }: PromotionResultProps) => {
    return(
        <div className='h-40 cursor-pointer border border-sun_yellow overflow-hidden bg-sun_yellow/10 rounded-xl inline-block relative ml-2 mr-2'>
            <div className="w-full h-24 flex flex-col flex-grow">
                <img className="h-full w-full" src={Image} alt={Product} />
                <div className="w-[80%] h-20 absolute top-[10%] left-[10%]">
                    <div className="bg-black/20 w-full h-full flex flex-col p-1 items-center justify-center text-white">
                        <span className='font-ArialRounded text-md text-wrap text-center overflow-hidden select-none'>{Product}</span>
                    </div>
                </div>
            </div>
            <div className='flex h-16 bg-brownish_grey_two rounded-b-lg px-1 sm:px-0'>
                {/* <div className='h-12 sm:w-[40%] md:w-[35%] lg:w-[30%] flex items-center justify-center'>
                    <img src={logo} className="h-10 w-10" alt="ZiTi" />
                </div> */}
                <div className='w-full text-left text-white p-2'>
                    <p className='font-BasierSquare text-xs italic text-ellipsis clip select-none'>{Description}</p>
                </div>
            </div>
        </div>
    )
}

export default PromotionResult;