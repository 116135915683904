import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

type FetchFunction  = (id: string, data:string)=>Promise<any>

const usePromoSearcher = (idParent:string, fetchFunction: FetchFunction) => {
    const [filter, setFilter] = useState('');
    const [promoResult, setPromoResult] = useState([]);
    const [value] = useDebounce(filter, 1000);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(()=>{
        if(value.length >= 2){
            searchPromo();
        }
    }, [value]);

    const setFilterText = (filter: string) => {
        if(filter.length === 0){
            setPromoResult([]);
            setIsSearching(false);
        }
        else if(filter.length>=5) setIsSearching(true);

        setFilter(filter);
    }

    const searchPromo = async() => {
        const res = await fetchFunction(idParent, value);
        
        setPromoResult(res);
        setIsSearching(false);
    }

    return {promoResult, isSearching, setFilterText}
}

export default usePromoSearcher;