import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SelectedSubCategory } from '../../store';

import { useZitiContext } from '../../store';
import SearchBar from '../../components/SearchBar';

import ItemButton from '../../components/ItemButton';

import logo from '../../assets/ziti_icon.png';
import usePromoSearcher from '../../hooks/usePromoSearcher';
import { getAssetUrl, searchPromosByCategory } from '../../services/ziti.business';
import PromotionResult from '../../components/PromotionResult';


const SubCategories = () => {
    const { selectedCategory, setCategory, setSubCategory } = useZitiContext();
    const [gridStyle, setGridStyle] = useState('grid sm:grid-cols-2 gap-x-8 gap-y-4');
    const navigate = useNavigate();

    const { promoResult, setFilterText, isSearching} = usePromoSearcher(selectedCategory.id, searchPromosByCategory)

    useEffect(()=>{
        if(!selectedCategory || selectedCategory.id === ''){
            backToCategories();
        }
    },[]);

    useEffect(()=>{
        console.log(promoResult)
        if(promoResult && promoResult.length >0){
            setGridStyle('grid md:grid-cols-4 sm:grid-cols-3 gap-y-4');
        }
        else{
            setGridStyle('grid sm:grid-cols-2 gap-x-8 gap-y-4');
        }
    }, [promoResult])

    const backToCategories = () => {
        setCategory({id: '', label:'', subCategories: []});
        navigate('/', {replace:true})
    }

    const renderSubCats = () => {
        let arrayData:any =[];
        const subCatData = selectedCategory.subCategories;
        
        if(promoResult && promoResult.length>0){
            promoResult.map((promo: any) => {
                const bUrl = getAssetUrl(promo.businessId,'','');
                arrayData.push(<PromotionResult key={promo._id} Product={promo.product} Description={promo.description} Image={`${bUrl.baseUrl}promos/${promo.image}`} 
                    Logo={''}/>)
            });
        }
        else if(subCatData && subCatData.length >= 1){
            subCatData.map((sCategory: any) => {
                arrayData.push(<ItemButton id={sCategory._id} label={sCategory.name} key={sCategory._id} subCategories={null} clickCallback={handleSubCategoryClick}/>);
            });
        }
        // else{
        //     return(
        //         <div className='col-span-2 p-4 font-BasierSquare text-xl text-center'>
        //             <p>Estamos agregando nuevas categorias para ti, Esperalas pronto.</p>
        //             <button className='mt-10 bg-sun_yellow text-white p-2 rounded-full min-h-12 lg:min-h-14 text-center items-center w-[35%] shadow-md' 
        //             onClick={() => backToCategories()}>Regresar</button>
        //         </div>
        //     )
        // }
        return arrayData;
    }

    const handleSubCategoryClick = (id: string, label: string) => {
        const subCat:SelectedSubCategory = {id, label};
        setSubCategory(subCat);
        navigate('/Business');
    }
    
    return(
        <div className="p-4 flex-col flex justify-center items-center">
            <div className='flex-col flex items-center w-full'>
                <img src={logo} className="h-14" alt="ZiTi" />
                <p className='mt-2 text-3xl uppercase font-ArialRounded text-center'>{selectedCategory.label}</p>
            </div>
            <SearchBar placeHolder='¿Que estas buscando?' 
                    description='Encuentra el negocio, producto o servicio que estes buscando.' 
                    inputCallback={setFilterText} isLoading={isSearching}/>
            <div className='w-full mt-10 flex items-center justify-center mb-10'>
                <div className={`w-[90%] lg:w-[75%] xl:w-[60%] ${gridStyle}`}>
                    {renderSubCats()}
                </div>
            </div>
        </div>
    )
}

export default SubCategories;