import Lottie from 'react-lottie-player';
import { validateString } from '../../utils';
import loading from '../../assets/animations/loading.json';

type SearchBarProps = {
    placeHolder: string,
    description: string|null,
    isLoading: boolean,
    inputCallback: (text: string) => void
}

const SearchBar = ({placeHolder, description, inputCallback, isLoading}:SearchBarProps) =>{
    const handleSearchInput = (val: any) => {
        if(validateString(val, true)){
            inputCallback(val.toLowerCase());
        }
        else if(val === ''){
            inputCallback('');
        }
    }

    return(
        <div className="mt-14 sm:w-[50%] flex-col items-center justify-center">
            <div className='h-10 relative'>
                <input className='bg-white w-full h-10 border-2 border-pinkishGrey focus:border-pinkishGrey rounded-full p-2' 
                    type="text" placeholder={placeHolder} onChange={(e) => handleSearchInput(e.target.value)}/>
                { isLoading &&
                    <div className='h-10 absolute top-0 right-2'>
                        <Lottie className='w-full h-full' animationData={loading} play loop speed={1.5}/>
                    </div>
                }
            </div>
            {description !== '' &&
                <div className='bg-brownish_grey px-4 rounded-b-lg pb-1 mt-[-1.25rem] pt-6'>
                    <p className='text-white text-sm select-none'>
                        {description}
                    </p>
                </div>
            }
        </div>
    )
}

export default SearchBar;
