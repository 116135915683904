import ziti_logo from '../assets/ziti_icon.png';
const API_KEY = process.env.REACT_APP_API;

type AssetsUrls = {
  baseUrl: string,
  logo: string,
  background: string
}

export function getBusinessInfo(business: string){
  return fetch(`${API_KEY}/business/${business}`,{
    method: 'GET',
    headers:{
      'Content-Type': 'application/json'
    }
  })
  .then(response => response.json())
  .then(responseJson =>{
    return responseJson;
  })
  .catch(error => console.error(error));
}

export function getPromosByBusiness(business: string) {
    return fetch(`${API_KEY}/promotions/${business}`,{
      method: 'GET',
      headers:{
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(responseJson =>{
      return responseJson;
    })
    .catch(error => console.error(error));
  }

  export function setLikeBusiness(business: string, data:{usr: string, like: boolean}, token:string){
    
    return fetch(`${API_KEY}/business/like/${business}`,{
      method: 'PATCH',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({usr: data.usr, like:data.like})
    })
    .then(response =>{
      return response.status;
    })
    .catch(error => {
      // console.error('Error al actualizar: ', error);
      return null;
    });
  }

  export function getAssetUrl(businessUser: string, logo_str: string, background_str: string): AssetsUrls {
    const baseUrl = process.env.REACT_APP_IMAGE_SERVER + businessUser + '/';
    const logo = logo_str? baseUrl + logo_str : ziti_logo;
    const background = background_str ? baseUrl + background_str : ziti_logo;

    return { baseUrl, logo, background };
  }

export function searchPromosBySubcategory(category: string, query: string){
  return fetch(`${API_KEY}/search/promotions/subcategory/${category}?search=${query}`,{
    method: 'GET',
    headers:{
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(responseJson =>{
    return responseJson;
  })
  .catch(error => console.error(error));
}

export function searchPromosByCategory(category: string, query: string){
  return fetch(`${API_KEY}/search/promotions/category/${category}?search=${query}`,{
    method: 'GET',
    headers:{
      'Content-Type': 'application/json'
    },
  })
  .then(response => response.json())
  .then(responseJson =>{
    return responseJson;
  })
  .catch(error => console.error(error));
}